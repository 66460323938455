import React, { FormEvent } from 'react';
import { useParticipantForm } from '../../hooks/forms/useParticipantForm';
import { Participant } from '../../types/api';

function getChefCode(): string | null {
  const match = window.location.search.match(/chef=(.*)&?/);
  const chefCode = match && match[1];
  return chefCode;
}

export const ParticipantForm: React.FC<{
  roomId: string;
  onParticipantCreated: (participant: Participant, jwt: string) => void;
}> = (props) => {
  const chefCode = getChefCode() || undefined;

  const { values, loading, handleSubmit, handleInputChange } = useParticipantForm(props.roomId, {
    name: '',
    chefCode,
  });

  const onSubmit = async (event: FormEvent) => {
    const { participant, jwt } = await handleSubmit(event);
    props.onParticipantCreated(participant, jwt);
  };

  return (
    <>
      <h1>Då kör vi!</h1>
      <p>
        Du kommer nu att kopplas ihop med övriga deltagare och matlagningen sätter igång. Skriv in ditt namn för att gå
        vidare!
      </p>
      <form onSubmit={onSubmit}>
        <label>
          Namn
          <input
            type="text"
            placeholder="Louise Andersson"
            name="name"
            onChange={handleInputChange}
            value={values.name}
          />
        </label>
        <button type="submit" disabled={loading}>
          Gå vidare
        </button>
      </form>
    </>
  );
};
