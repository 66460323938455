import React from 'react';
import { AudioTracks } from '../../components/AudioTracks';
import LocalVideoPreview from '../../components/LocalVideoPreview/LocalVideoPreview';
import MainParticipant from '../../components/MainParticipant/MainParticipant';
import { MuteButton } from '../../components/MuteButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { StyledUserInterface } from './style';

export const UserInterface: React.FC = () => {
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';

  return (
    <StyledUserInterface>
      <AudioTracks />
      <MuteButton disabled={isReconnecting} />
      <LocalVideoPreview />
      <MainParticipant />
    </StyledUserInterface>
  );
};
