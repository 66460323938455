import styled from 'styled-components';

export const StyledHeader = styled.header`
  padding-top: 16px;
  display: flex;
  h1 {
    svg {
      height: 2rem;
      g {
        fill: var(--color);
      }
    }
  }
  nav {
  }
`;
