import styled from 'styled-components';
import { StyledLocalVideoPreview } from '../../components/LocalVideoPreview/style';
import { StyledMainParticipant } from '../../components/MainParticipant/style';
import { StyledMuteButton } from '../../components/MuteButton/style';

export const StyledUserInterface = styled.div`
  ${StyledLocalVideoPreview} {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 300px;
    max-width: 30%;
    z-index: 3;
  }

  ${StyledMainParticipant} {
    width: 100%;
    height: 100%;
  }

  ${StyledMuteButton} {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 3;
  }
`;
