import styled from 'styled-components';
import { StyledVideoTrack } from '../VideoTrack/style';

export const StyledMainParticipant = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${StyledVideoTrack} {
    width: 100%;
  }
`;
