import { useState, ChangeEvent, FormEvent } from 'react';

export const useForm = <T extends Record<string, any>, R = any>(
  initialValues: T,
  submitFn: (values: T) => Promise<R>
) => {
  const [values, setValues] = useState<T>(initialValues);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setValues((state) => ({ ...state, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (event: FormEvent): Promise<R> => {
    event.preventDefault();
    setLoading(true);
    setError(null);
    try {
      return submitFn(values);
    } catch (e) {
      setError(e);
      return Promise.reject(e);
    } finally {
      setLoading(false);
    }
  };

  return { values, loading, error, handleSubmit, handleInputChange };
};
