import React from 'react';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import usePublications from '../../hooks/usePublications/usePublications';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import VideoPublication from '../VideoPublication/VideoPublication';
import { StyledMainParticipant } from './style';

export default function MainParticipant() {
  const { room } = useVideoContext();
  const mainParticipant = useMainSpeaker();
  const isLocal = mainParticipant === room.localParticipant;
  const publications = usePublications(mainParticipant);

  const videoPublication = publications.find((p) => p.kind === 'video');
  const videoPriority = 'high';

  return (
    <StyledMainParticipant>
      {/* audio is disabled for this participant component because this participant's audio 
       is already being rendered in the <ParticipantStrip /> component.  */}
      {videoPublication ? (
        <VideoPublication
          key={videoPublication.kind}
          publication={videoPublication}
          participant={mainParticipant}
          isLocal={isLocal}
          videoPriority={videoPriority}
        />
      ) : (
        <p>Video saknas</p>
      )}
    </StyledMainParticipant>
  );
}
