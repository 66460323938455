import { ApolloError } from 'apollo-boost';
import React, { createContext, useContext, useState } from 'react';
import { TwilioError } from 'twilio-video';

export interface StateContextType {
  error: TwilioError | ApolloError | null;
  setError(error: TwilioError | ApolloError | null): void;
  // getRoom(code: string): Promise<Room>;
  // // getToken(participantId: string, roomId: string): Promise<string>;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | ApolloError | null>(null);

  // const getToken: StateContextType['getToken'] = async (participantId, roomId) => {
  //   try {
  //     setIsFetching(true);
  //     const headers = new window.Headers();
  //     const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
  //     const params = new window.URLSearchParams({ participantId, roomId });
  //     console.log(params);

  //     return fetch(`${endpoint}?${params}`, { headers }).then((res) => res.text());
  //   } catch (err) {
  //     setError(err);
  //     return Promise.reject(err);
  //   } finally {
  //     setIsFetching(false);
  //   }
  // };

  const contextValue: StateContextType = {
    error,
    setError,
  };

  return <StateContext.Provider value={contextValue}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
