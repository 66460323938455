import { ApolloProvider } from '@apollo/react-hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { client } from './apollo/client';
import { App } from './app';
import { GlobalStyle } from './components/GlobalStyle';
import * as serviceWorker from './serviceWorker';
import AppStateProvider from './state';
import './styles/_mvp.css';

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ApolloProvider client={client}>
      <Router>
        <AppStateProvider>
          <Switch>
            <Route path="/cook/:roomCode">
              <App />
            </Route>
          </Switch>
        </AppStateProvider>
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
