import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { JoinRoomInput, JoinRoomPayload } from '../../types/api';
import { useForm } from './useForm';

const JOIN_ROOM_MUTATION = gql`
  mutation JoinRoom($input: JoinRoomInput!) {
    joinRoom(input: $input) {
      participant {
        id
        name
        token
      }
      jwt
    }
  }
`;

export const useParticipantForm = (
  roomId: string,
  initialValues: { name: string; chefCode?: string } = { name: '' }
) => {
  const [joinRoom] = useMutation<{ joinRoom: JoinRoomPayload }>(JOIN_ROOM_MUTATION);

  return useForm<JoinRoomInput, JoinRoomPayload>({ ...initialValues, roomId }, async (values) => {
    const { data, errors } = await joinRoom({ variables: { input: values } });
    if (!data) return Promise.reject(errors);
    return data?.joinRoom;
  });
};
