import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useRoomByIdQuery from '../useRoomByIdQuery/useRoomByIdQuery';
import useTwilioParticipants from '../useTwilioParticipants/useTwilioParticipants';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useChefSpeaker(): RemoteParticipant | null {
  const [chef, setChef] = useState<RemoteParticipant | null>(null);
  const { room: twilioRoom } = useVideoContext();
  const { room } = useRoomByIdQuery(twilioRoom.name);
  const participants = useTwilioParticipants();

  useEffect(() => {
    const participant = room?.chef;
    setChef(participants.find((a) => a.identity === participant?.id) || null);
  }, [participants, room]);

  return chef;
}
