import React from 'react';
import { StyledHeader } from './style';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';

export const Header: React.FC = ({ children, ...props }) => {
  return (
    <StyledHeader>
      <h1>
        <Logo />
      </h1>
    </StyledHeader>
  );
};
