import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorDialog from '../components/ErrorDialog';
import useHeight from '../hooks/useHeight/useHeight';
import useRoomByCodeQuery from '../hooks/useRoomByCodeQuery/useRoomByCodeQuery';
import { useAppState } from '../state';
import { Participant } from '../types/api';
import { DefaultLayout } from '../views/DefaultLayout';
import { ParticipantForm } from '../views/ParticipantForm';
import { VideoApp } from '../views/VideoApp';
import { StyledApp } from './style';

const GET_ME_QUERY = gql`
  {
    me {
      id
      name
      token
      isChef
    }
  }
`;

const usePersistedParticipant = (roomCode: string) => {
  const persistedParticipantId = localStorage.getItem('participant-jwt');
  const persistedRoomCode = localStorage.getItem('participant-room-code');

  const [participant, setParticipant] = useState<Participant | null>(null);

  const { loading, error, data } = useQuery<{ me: Participant }>(GET_ME_QUERY, {
    skip: !persistedParticipantId || persistedRoomCode !== roomCode,
  });

  useEffect(() => {
    setParticipant(data?.me || null);
  }, [data]);

  return {
    loading,
    error,
    participant,
  };
};

export const App: React.FC = () => {
  const { roomCode } = useParams<{ roomCode: string }>();
  const { error, setError } = useAppState();
  const { room, loading: roomLoading, error: roomError } = useRoomByCodeQuery(roomCode);
  const {
    participant: persistedParticipant,
    loading: participantLoading,
    error: participantError,
  } = usePersistedParticipant(roomCode);

  const [isLoading, setIsLoading] = useState(true);
  const [participant, setParticipant] = useState<Participant | null>(null);

  const handleParticipantCreated = (newParticipant: Participant, jwt: string) => {
    localStorage.setItem('participant-jwt', jwt);
    localStorage.setItem('participant-room-code', roomCode);
    setParticipant(newParticipant);
  };

  useEffect(() => {
    setIsLoading(roomLoading || participantLoading);
  }, [roomLoading, participantLoading, setIsLoading]);

  useEffect(() => {
    setError(roomError || participantError || null);
  }, [roomError, participantError, setError]);

  useEffect(() => {
    setParticipant(persistedParticipant);
  }, [persistedParticipant]);

  const height = useHeight();

  return (
    <StyledApp style={{ height }}>
      {error && <ErrorDialog dismissError={() => setError(null)} error={error} />}
      {participant ? (
        <VideoApp participant={participant} />
      ) : (
        <DefaultLayout>
          <main>
            {isLoading ? (
              <h1>Laddar...</h1>
            ) : room ? (
              <ParticipantForm roomId={room.id} onParticipantCreated={handleParticipantCreated} />
            ) : (
              <h1>Ogiltig kod</h1>
            )}
          </main>
        </DefaultLayout>
      )}
    </StyledApp>
  );
};
