import React from 'react';
import { StyledMuteButton } from './style';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle/useLocalAudioToggle';

export const MuteButton: React.FC<{ disabled?: boolean }> = ({ children, ...props }) => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  return (
    <StyledMuteButton onClick={toggleAudioEnabled} disabled={props.disabled}>
      {isAudioEnabled ? 'M' : 'U'}
    </StyledMuteButton>
  );
};
