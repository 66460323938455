import React, { PropsWithChildren } from 'react';
import { TwilioError } from 'twilio-video';
import { ApolloError } from 'apollo-boost';
import { StyledErrorDialog } from './style';

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | ApolloError | null;
}

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const { message } = error || {};

  return (
    <StyledErrorDialog onClick={() => dismissError()}>
      <p>{message}</p>
      <p>Stäng</p>
    </StyledErrorDialog>
  );
}

export default ErrorDialog;
