import styled from 'styled-components';

export const StyledControlButton = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  box-shadow: var(--box-shadow) var(--color-shadow);
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
