import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useTwilioParticipants(): RemoteParticipant[] {
  const { room } = useVideoContext();
  const [roomParticipants, setRoomParticipants] = useState<RemoteParticipant[]>(
    room.participants ? Array.from(room.participants.values()) : []
  );

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) =>
      setRoomParticipants((prevParticipants) => [...prevParticipants, participant]);
    const participantDisconnected = (participant: RemoteParticipant) =>
      setRoomParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return roomParticipants;
}
