import React from 'react';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { StyledLocalVideoPreview } from './style';

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find((track) => track.name === 'camera') as LocalVideoTrack;

  return (
    <StyledLocalVideoPreview>{videoTrack ? <VideoTrack track={videoTrack} isLocal /> : null}</StyledLocalVideoPreview>
  );
}
