import React from 'react';
import { LocalTrackPublication, Participant, RemoteTrackPublication, Track } from 'twilio-video';
import useTrack from '../../hooks/useTrack/useTrack';
import { IVideoTrack } from '../../types/twilio';
import VideoTrack from '../VideoTrack';

interface VideoPublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocal: boolean;
  videoPriority?: Track.Priority | null;
}

export default function VideoPublication({ publication, isLocal, videoPriority }: VideoPublicationProps) {
  const track = useTrack(publication);

  if (!track) return null;

  return (
    <VideoTrack track={track as IVideoTrack} priority={videoPriority} isLocal={track.name === 'camera' && isLocal} />
  );
}
