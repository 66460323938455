import styled from 'styled-components';

export const StyledErrorDialog = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: var(--color);
  color: var(--color-text-secondary);
  display: flex;
  justify-content: space-between;
`;
