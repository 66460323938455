import styled from 'styled-components';
import { StyledChefInterface } from '../ChefInterface/style';
import { StyledUserInterface } from '../UserInterface/style';

export const StyledVideoApp = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--color-black);
  display: flex;
  align-items: center;
  justify-content: center;
  .loading-indicator {
    text-align: center;
  }
  ${StyledChefInterface}, ${StyledUserInterface} {
    width: 100%;
    height: 100%;
  }
`;
