import React from 'react';
import LocalVideoPreview from '../../components/LocalVideoPreview/LocalVideoPreview';
import MainParticipant from '../../components/MainParticipant/MainParticipant';
import { StyledChefInterface } from './style';
import { MuteButton } from '../../components/MuteButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { AudioTracks } from '../../components/AudioTracks';

export const ChefInterface: React.FC = () => {
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';

  return (
    <StyledChefInterface>
      <AudioTracks />
      <MuteButton disabled={isReconnecting} />
      <LocalVideoPreview />
      <MainParticipant />
    </StyledChefInterface>
  );
};
