import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';
import { RemoteParticipant, LocalParticipant } from 'twilio-video';
import useChefSpeaker from '../useChefSpeaker/useChefSpeaker';

export default function useMainSpeaker(): RemoteParticipant | LocalParticipant {
  const dominantSpeaker = useDominantSpeaker();
  const chefSpeaker = useChefSpeaker();
  const {
    room: { localParticipant },
  } = useVideoContext();

  // The participant that is returned is displayed in the main video area. Changing the order of the following
  // variables will change the how the main speaker is determined.
  return dominantSpeaker || chefSpeaker || localParticipant;
}
