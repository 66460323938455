import React from 'react';
import { RemoteParticipant } from 'twilio-video';
import usePublications from '../../hooks/usePublications/usePublications';
import useTwilioParticipants from '../../hooks/useTwilioParticipants/useTwilioParticipants';
import { AudioPublication } from '../AudioPublication';

const ParticipantAudioTrack: React.FC<{ participant: RemoteParticipant }> = ({ children, ...props }) => {
  const publications = usePublications(props.participant);
  const audioPublications = publications.filter((a) => a.kind === 'audio');

  return (
    <>
      {audioPublications.map((publication) => (
        <AudioPublication publication={publication} key={publication.trackSid} />
      ))}
    </>
  );
};

export const AudioTracks: React.FC = () => {
  const participants = useTwilioParticipants();
  return (
    <>
      {participants.map((participant) => (
        <ParticipantAudioTrack participant={participant} key={participant.identity} />
      ))}
    </>
  );
};
