import ApolloClient from 'apollo-boost';

export const client = new ApolloClient({
  uri: process.env.REACT_APP_SERVER_URL + '/graphql',
  request: (operation) => {
    const token = localStorage.getItem('participant-jwt');
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    });
  },
});
