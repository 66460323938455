import { gql, ApolloError } from 'apollo-boost';
import { Room } from '../../types/api';
import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

const GET_ROOM_QUERY = gql`
  query RoomById($id: String!) {
    room(id: $id) {
      id
      muteParticipants
      chef {
        id
        name
      }
    }
  }
`;

export default function useRoomByIdQuery(roomId: string): { loading: boolean; room: Room | null; error?: ApolloError } {
  const [room, setRoom] = useState<Room | null>(null);

  const { loading, data, error } = useQuery<{ room: Room }>(GET_ROOM_QUERY, {
    variables: { id: roomId },
    skip: !roomId,
  });

  useEffect(() => {
    setRoom(data?.room || null);
  }, [data]);

  return { loading, room, error };
}
