import React, { useEffect } from 'react';
import { ConnectOptions } from 'twilio-video';
import LocalVideoPreview from '../../components/LocalVideoPreview/LocalVideoPreview';
import MainParticipant from '../../components/MainParticipant/MainParticipant';
import { VideoProvider } from '../../components/VideoProvider';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { Participant } from '../../types/api';
import { StyledVideoApp } from './style';
import { UserInterface } from '../UserInterface';
import { ChefInterface } from '../ChefInterface';

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      dominantSpeakerPriority: 'standard',
      renderDimensions: {
        high: { height: 1080, width: 1920 },
        standard: { height: 720, width: 1280 },
        low: { height: 90, width: 160 },
      },
    },
  },
  dominantSpeaker: true,
  maxAudioBitrate: 12000,
  networkQuality: { local: 1, remote: 1 },
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
};

export const VideoApp: React.FC<{ participant: Participant }> = (props) => {
  const { setError } = useAppState();

  const Content: React.FC<{ token: string; isChef: boolean }> = ({ token, isChef }) => {
    const roomState = useRoomState();
    const { connect, isConnecting } = useVideoContext();

    useEffect(() => {
      if (!isConnecting) {
        console.log('CONNECT with token ' + token);
        connect(token);
      }
    }, [token]);

    return (
      <StyledVideoApp>
        {isConnecting || roomState === 'disconnected' ? (
          <div className="loading-indicator">Laddar...</div>
        ) : isChef ? (
          <ChefInterface />
        ) : (
          <UserInterface />
        )}
      </StyledVideoApp>
    );
  };

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <Content token={props.participant.token} isChef={props.participant.isChef} />
    </VideoProvider>
  );
};
