import React from 'react';
import { AudioTrack as IAudioTrack, LocalTrackPublication, RemoteTrackPublication } from 'twilio-video';
import useTrack from '../../hooks/useTrack/useTrack';
import AudioTrack from '../AudioTrack/AudioTrack';

export const AudioPublication: React.FC<{ publication: RemoteTrackPublication | LocalTrackPublication }> = (props) => {
  const track = useTrack(props.publication);

  if (!track) return null;

  return <AudioTrack track={track as IAudioTrack} />;
};
